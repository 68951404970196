<template>
  <ECharts
    theme="cycloid"
    autoresize
    :option="options"
    @click="clickHandler"/>
</template>

<script>
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment

export default {
  name: 'CyEventsFrequencyChart',
  props: {
    begin: {
      type: Number,
      required: true,
    },
    end: {
      type: Number,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    $static () {
      return {
        intervals: 200,
        colorsBySeverities: {
          crit: '#e6213c',
          err: '#e6213c',
          warn: '#fbbc0f',
          info: '#1c9797',
        },
      }
    },
    normalizedEnd () {
      return Math.min(this.end, Date.now())
    },
    intervalDuration () {
      return (this.normalizedEnd - this.begin) / this.$static.intervals
    },
    eventsIntervalsBySeverity () {
      return ['info', 'warn', 'err', 'crit'].reduce((acc, severity) => {
        acc[severity] = _.times(this.$static.intervals).map((value, index) => {
          const intervalBegin = this.begin + (this.intervalDuration * index)
          const intervalEnd = intervalBegin + this.intervalDuration
          return [
            _.filter(this.events, (event) => event.severity === severity && _.inRange(event.timestamp, intervalBegin, intervalEnd)).length,
            intervalBegin + (this.intervalDuration / 2),
          ]
        })
        return acc
      }, {})
    },
    options () {
      return {
        series: [
          ...this.eventsSeries,
          ...this.markAreas,
        ],
        grid: {
          top: 24,
          right: 20,
          bottom: 12,
          left: 72,
        },
        xAxis: {
          type: 'value',
          min: 0,
          max: 'dataMax',
          minInterval: 1,
          axisLabel: { formatter: this.formatCount },
          position: 'top',
          splitNumber: 4,
        },
        yAxis: {
          type: 'time',
          min: this.begin,
          max: this.normalizedEnd,
          axisLabel: {
            formatter: this.formatTimestamp,
            showMaxLabel: true,
            showMinLabel: true,
          },
          axisLine: { show: true, lineStyle: { color: '#90A5C1' } },
          axisTick: { show: true, lineStyle: { color: '#90A5C1' } },
          splitLine: { show: true, lineStyle: { color: '#EDF1F7' } },
          splitNumber: 12,
          minInterval: 3600 * 1000 * 24,
        },
        animation: false,
      }
    },
    eventsSeries () {
      return _.map(this.$static.colorsBySeverities, (color, severity) => {
        return {
          name: severity,
          type: 'bar',
          data: this.eventsIntervalsBySeverity[severity],
          stack: 'one',
          large: true,
          itemStyle: { color },
          silent: true,
        }
      })
    },
    markAreas () {
      if (_.isEmpty(this.pagination)) return []
      const { itemsPerPage, page: currentPage } = this.pagination
      const pagesCount = Math.ceil(this.events.length / itemsPerPage)
      let highlightCoords

      const areas = _.range(pagesCount).reduce((result, page) => {
        const isCurrentPage = page === currentPage - 1
        const beginEvent = this.events[page * itemsPerPage]
        const endEvent = this.events[Math.min((page * itemsPerPage) + itemsPerPage - 1, this.events.length - 1)]
        const yAxisCoords = {
          begin: (Math.ceil((beginEvent.timestamp - this.begin) / this.intervalDuration) * this.intervalDuration + this.begin),
          end: (Math.floor((endEvent.timestamp - this.begin) / this.intervalDuration) * this.intervalDuration + this.begin),
        }
        if (isCurrentPage) { highlightCoords = yAxisCoords }
        result.push([
          {
            yAxis: yAxisCoords.begin,
            ...(isCurrentPage
              ? {
                  itemStyle: {
                    // equivalent to cy-get-color("grey", "light-2")
                    color: 'rgba(87, 110, 142, 0.1)',
                    borderWidth: 1,
                    borderColor: '#90A5C1',
                  },
                }
              : null),
          },
          { yAxis: yAxisCoords.end },
        ])

        return result
      }, [])

      const pagesAreas = {
        name: 'mark',
        type: 'line',
        markArea: {
          data: areas,
          itemStyle: {
            // equivalent to cy-get-color("grey", "light-2")
            color: 'rgba(87, 110, 142, 0)',
          },
          emphasis: {
            itemStyle: {
              color: 'rgba(87, 110, 142, 0.2)',
            },
          },
        },
        silent: false,
      }

      const highlightArea = highlightCoords
        ? {
            name: 'mark',
            type: 'line',
            markArea: {
              data: [[
                { x: '67', yAxis: highlightCoords.begin },
                { x: '72', yAxis: highlightCoords.end },
              ]],
              itemStyle: {
                // equivalent to cy-get-color("red", "main")
                color: 'rgba(230, 33, 60, 0.5)',
              },
            },
            silent: true,
          }
        : {}

      return [pagesAreas, highlightArea]
    },
  },

  methods: {
    clickHandler ({ componentType, dataIndex = 0 } = {}) {
      if (componentType !== 'markArea') return
      this.$emit('goToPage', dataIndex + 1)
    },
    formatCount (count) {
      return Intl.NumberFormat('en', { notation: 'compact' }).format(count)
    },
    formatTimestamp (timestamp) {
      const day = moment(timestamp).format('YYYY-MM-DD') // eslint-disable-line you-dont-need-momentjs/format
      const time = moment(timestamp).format('h:mm a') // eslint-disable-line you-dont-need-momentjs/format
      return `${day}\n${time}`
    },
  },
}
</script>
